import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Header from "../components/header";
import Seo from "../components/seo";
import TwoColumnLayout from '../components/twoColumnLayout';
import styled from "styled-components";
import MEDIA from '../helpers/mediaTemplates';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ImageGrid1x1 = styled(GatsbyImage)`
 width: 100%;
 grid-column-start: 1;
 grid-column-end: 3;
 ${MEDIA.PHONE`
   grid-column-start: 1;
   grid-column-end: 1;
   `};
`

const GridContainer = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(50% - 2rem, 0fr));
grid-gap: 2rem;
margin: 2rem;
${MEDIA.PHONE`
  grid-template-columns: repeat(auto-fill, minmax(100%, 0fr));
  `};
`

const TextContainer = styled.div`
position: fixed;
top: 0;
bottom: 0;
max-width: 533px;
z-index: 1000;
overflow-y: auto;
-ms-overflow-style: none;
scrollbar-width: none;
padding-right: 2rem;
padding-top: 2rem;
&::-webkit-scrollbar {
    display: none;
}
${MEDIA.TABLET`
    width: 100%;
    position: static;
    align-items: left;
    padding: 2rem;
    padding-bottom: 0;
    z-index: 10;
  `};
${MEDIA.PHONE`
  width: 100%;
  position: static;
  align-items: left;
  padding: 2rem;
  padding-bottom: 0;
  z-index: 10;
  `};
`

const LayeredHorizons = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "layeredHorizons"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            name
          }
        }
      }
    }
  `)
  const images = [];
  for (let i = 0; i < data.allFile.edges.length; i++) {
    images[i] = getImage(data.allFile.edges[i].node)
  }
  
    return(
          <TwoColumnLayout
          Column1Size="66"
          Column1Content={
            <GridContainer>
            {images.map((image, index) => (
                <ImageGrid1x1 image={image} alt={data.allFile.edges[index].node.name} key={index}/>
            ))}
            </GridContainer>
          }
          Column2Size="33"
          Column2Content={
            <TextContainer>
            <Seo title="Layered-Horizons" />
            <Header siteTitle="Jake Mu" />
            <h2>
              <i>Layered Horizons</i>
              <br /><br />
              {'Layered Horizons is an ongoing research project conducted at the University of Technology Sydney in partnership with Western Sydney University as part of a 4 year research project conducted by Andrew Burrell and Rachel Hendry.'}
              <br /><br />
              {'Using Unity, we created a VR environment in which researchers could input location-based datasets such as spoken languages or the introduction of yams, which would then appear in VR as geographical layers. The project forced us to think about different interpretations of data in different contexts to provide a tool that was accessible and intuitive when exploring immersive research and what these new perspectives might create.'}
              <br /><br />
              <b>role:</b><br />
              <i>{'– Concepting'}</i><br />
              <i>{'– Design'}</i><br />
              <i>{'– Development'}</i><br />
              <br />
              <b>tech & skills:</b><br />
              <i>{'– Unity VR development'}</i><br />
              <i>{'– Designing for 3D virtual environments'}</i><br />
              <i>{'– Data Visualisation'}</i><br />
            </h2>
          </TextContainer>
  
          }
          />
    )
  }
  
  export default LayeredHorizons;
  